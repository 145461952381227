'use client';

import Icon from '@egalite/ui/Icon';
import Typography from '@egalite/ui/Typography';
import cn from '@egalite/ui/Utils/cn';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import React from 'react';

type Options = { link?: string; href?: string; duration?: number };

const NotificationContext = React.createContext({
	success: (message: string, opt?: Options) => {},
	error: (message: string, opt?: Options) => {},
});

type Props = {
	children: React.ReactNode;
};

const NotificationProvider: React.FC<Props> = ({ children }) => {
	const [notifications, setNotifications] = React.useState<Map<string, { message: string; type: string } & Options>>(new Map());

	const handleAddToast = React.useCallback((toast: { message: string; type: string } & Options) => {
		setNotifications(prev => {
			const newMap = new Map(prev);
			newMap.set(String(Date.now()), { ...toast });
			return newMap;
		});
	}, []);

	const handleRemoveToast = React.useCallback((key: string) => {
		setNotifications(prev => {
			const newMap = new Map(prev);
			newMap.delete(key);
			return newMap;
		});
	}, []);

	const handleDispatchSuccess = React.useCallback(
		(message: string, opt: Options = {}) => handleAddToast({ message, type: 'success', ...opt }),
		[handleAddToast],
	);

	const handleDispatchError = React.useCallback(
		(message: string, opt: Options = {}) => handleAddToast({ message, type: 'error', ...opt }),
		[handleAddToast],
	);

	return (
		<NotificationContext.Provider
			value={React.useMemo(
				() => ({
					success: handleDispatchSuccess,
					error: handleDispatchError,
				}),
				[handleDispatchSuccess, handleDispatchError],
			)}
		>
			<ToastPrimitive.Provider>
				<AnimatePresence>
					{Array.from(notifications).map(([key, notification]) => {
						return (
							<ToastPrimitive.Root
								className="flex justify-between items-center p-6 max-w-[400px] rounded-2xl shadow-lg bg-white"
								onOpenChange={open => {
									if (!open) handleRemoveToast(key);
								}}
								key={key}
								duration={notification.duration}
								asChild
								forceMount
							>
								<motion.li
									initial={{
										y: -100,
										scale: 0.6,
										opacity: 0,
									}}
									animate={{
										y: 0,
										scale: 1,
										opacity: 1,
										transition: { duration: 0.3 },
									}}
									exit={{
										scale: 0.9,
										opacity: 0,
										transition: { duration: 0.15 },
									}}
									layout
								>
									<div className="flex gap-6 items-center text-base-grey-6">
										<div
											className={cn(
												'flex items-center justify-center w-[24px] h-[24px] rounded-full text-base flex-shrink-0 text-white',
												{
													'bg-brand-main': notification.type === 'success',
													'bg-error-dark': notification.type === 'error',
												},
											)}
											aria-hidden
										>
											{notification.type === 'success' ? <Icon name="check" /> : <Icon name="close-2" />}
										</div>

										<ToastPrimitive.Description>
											<Typography variant="body-3">{notification.message}</Typography>

											{notification.href && (
												<Link href={notification.href} target="_blank" rel="noreferrer noopener">
													<Typography variant="body-3" className="text-brand-main underline cursor-pointer">
														{notification.link || notification.href}
													</Typography>
												</Link>
											)}
										</ToastPrimitive.Description>

										<ToastPrimitive.Close className="flex items-center justify-center w-[23px] h-[23px] rounded-full text-base flex-shrink-0 border border-solid border-base-grey-6 hover:bg-base-grey-6 hover:text-white">
											<Icon name="close-2" />
										</ToastPrimitive.Close>
									</div>
								</motion.li>
							</ToastPrimitive.Root>
						);
					})}
				</AnimatePresence>

				<ToastPrimitive.Viewport className="fixed p-20 flex flex-col-reverse gap-6 top-0 right-0 z-50" />

				{children}
			</ToastPrimitive.Provider>
		</NotificationContext.Provider>
	);
};

export const useNotification = () => {
	const context = React.useContext(NotificationContext);
	if (context) return context;
	throw new Error('useNotification must be used within Notifications');
};

export default NotificationProvider;
