'use client';

import { useNotification } from '@egalite/ui/IncluiPCD/NotificationProvider';
import { FC, ReactNode, useEffect } from 'react';
import { proxy } from 'valtio';

export const liveStore = proxy<{ liveId?: string }>({ liveId: null });

type Props = {
	children?: ReactNode;
};

const LiveUpdater: FC<Props> = ({ children }) => {
	const notification = useNotification();

	useEffect(() => {
		(async () => {
			while (true) {
				try {
					const result = await fetch('/api/v2/utils/lives-long-polling', { cache: 'no-store' });

					const data = await result.json();

					if (result.status !== 200) throw new Error(data?.errors);

					const liveId = data?.data?.videoId; // 1000 * 60 * 10

					const startAtDifference = (Date.parse(data?.data?.startAt) || 0) - Date.now();
					const tenMinutes = 1000 * 60 * 10;

					console.log('startAtDifference', startAtDifference);

					if (startAtDifference <= tenMinutes && startAtDifference > 0) {
						notification.success('Uma nova live está prestes a começar!', {
							link: 'Clique aqui para acessar',
							href: `/programacao`,
							duration: 15_000,
						});
					}

					if (liveId !== liveStore.liveId) {
						liveStore.liveId = liveId;
					}
				} catch (err) {
					console.error(err);

					await new Promise(resolve => setTimeout(resolve, 60_000));
				}
			}
		})();
	}, []);

	return <>{children}</>;
};

export default LiveUpdater;
