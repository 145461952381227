import { FC, ReactNode } from 'react';
import cn from '../Utils/cn';

type Props = {
	className?: string;
	children: ReactNode;
	id?: string;
};

const Section: FC<Props> = ({ children, className, id }) => {
	return (
		<section id={id} className={cn('px-8 py-16 lg:py-48 flex flex-col gap-8 lg:gap-16 items-center text-base-grey-5 container mx-auto', className)}>
			{children}
		</section>
	);
};

export default Section;
