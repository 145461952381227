const generateOpportunityUrl = (opportunity: {
    cod: number;
    jobTitle: string;
    companyName?: string;
    city?: string;
    salary?: number | string;
    edRequirement: string;
    edStatus?: string;
    sector?: string;
}) => {
    const oppTitle = opportunity.jobTitle?.toLowerCase();
    const oppCity = opportunity.city?.toLowerCase();

    const urlSegment = [oppTitle, oppCity]
        .flatMap(val => val ? val.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9-]/g, " ").trim().split(/\s+/).join("-") : [])
        .join('-');

    return `/vaga-pcd/${urlSegment}/${opportunity.cod}`;
};

export default generateOpportunityUrl;
