'use client';
import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import Typography from '@egalite/ui/Typography';

const AccordionItem = ({ value, title, children }) => (
    <Accordion.Item value={value} className="border mb-4 rounded-xl overflow-hidden shadow-md">
        <Accordion.Header>
            <Accordion.Trigger asChild>
                <button className="flex justify-between items-center p-8 gap-8 text-lg w-full bg-white">
                    <Typography variant="headline-4" asEl='span' className="text-left flex-1">{title}</Typography>
                    <i className="fi fi-br-plus text-brand-main"></i>
                </button>
            </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content asChild>
            <div className="p-8 transition-all duration-700 ease-in-out bg-white">
                <Typography variant="body-1" className="text-left">{children}</Typography>
            </div>
        </Accordion.Content>
    </Accordion.Item>
);

function FAQ() {
    return (
        <Accordion.Root className="w-full max-w-[980px] mx-auto my-16 mb-32" type="single" collapsible>
            <Typography variant="headline-2" className="mb-16 text-center">
                Perguntas Frequentes
            </Typography>
            <AccordionItem value="item-1" title="1. Como posso me candidatar às vagas?">
                Para se candidatar, primeiro você precisa se cadastrar em nosso site. Após o registro, você pode acessar nosso portal de vagas, onde poderá buscar e se candidatar a oportunidades que correspondam ao seu perfil e interesses.
            </AccordionItem>
            <AccordionItem value="item-2" title="2. Existem vagas para todos os tipos de deficiência?">
                Sim, oferecemos oportunidades para pessoas com diferentes tipos de deficiência. Nosso objetivo é garantir que todos os candidatos encontrem vagas adequadas às suas habilidades e capacidades.
            </AccordionItem>
            <AccordionItem value="item-3" title="3. Posso participar da IncluiPcD se estou procurando meu primeiro emprego?">
                Sim! A IncluiPcD acolhe profissionais de todos os níveis de experiência, incluindo aqueles que buscam seu primeiro emprego. Incentivamos a todos que se registrem e explorem as oportunidades oferecidas, tanto na feira quanto na plataforma digital.
            </AccordionItem>
            <AccordionItem value="item-4" title="4. A IncluiPCD é gratuita?">
                Para candidatos em busca de emprego, tanto a feira quanto o acesso à plataforma digital são totalmente gratuitos. As empresas também podem se cadastrar gratuitamente, mas oferecemos diversas outras soluções mais completas adaptadas às suas necessidades de recrutamento e promoção da inclusão.
            </AccordionItem>
            <AccordionItem value="item-5" title="5. Como as empresas podem se tornar parceiras e participar da IncluiPcD?">
                Empresas interessadas em se juntar à feira anual ou anunciar vagas na plataforma podem entrar em contato conosco através da aba de empresas. Fornecemos todas as informações e suporte necessários para facilitar sua participação e promover a inclusão efetiva em suas organizações.
            </AccordionItem>
            <AccordionItem value="item-6" title="6. Existem vagas em todo território nacional?">
                Sim, a plataforma da IncluiPCD oferece vagas em todo o território nacional, proporcionando oportunidades de emprego para profissionais com deficiência em diversas regiões do país.
            </AccordionItem>
            <AccordionItem value="item-7" title="7. A feira acontece quantas vezes por ano?">
                A feira da IncluiPcD é um evento que ocorre no mês de setembro, complementando a oferta contínua de vagas disponíveis na nossa plataforma digital. Temos algumas outras edições ao longo do ano, mas o principal é sempre em setembro.
            </AccordionItem>
            <AccordionItem value="item-8" title="8. Como é o processo seletivo?">
                A IncluiPcD é uma plataforma que conecta os candidatos e as empresas. Cada empresa é responsável por gerenciar seu processo seletivo, podendo ser diferentes dependendo da empresa ou vaga. Geralmente, inclui análise de currículo, entrevistas, e, em alguns casos, testes práticos de habilidades. As empresas podem também organizar processos seletivos específicos durante a feira online, proporcionando interações diretas entre candidatos e recrutadores.
            </AccordionItem>
            <AccordionItem value="item-9" title="9. Esqueci a minha senha, como posso recuperá-la?">
                Você pode recuperar a sua senha por <a href="https://incluipcd.com.br/login/recover" className="text-blue-600 hover:underline">este link</a>, basta inserir seu e-mail cadastrado.
            </AccordionItem>
        </Accordion.Root>
    );
}

export default FAQ;