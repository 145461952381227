import cn from '../Utils/cn';
import { FC, HTMLAttributes, ReactNode, useMemo } from 'react';
import deprecationWarning from '../Utils/deprecationWarning';

type AllowedTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'pre' | 'span' | 'li' | 'th' | 'td' | 'div' | 'label';

type DeprecatedVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subheadline1' | 'subheadline2' | 'subheadline3' | 'subheadline4' | 'body1' | 'body2' | 'body3';

type Variants =
	| 'headline-1'
	| 'headline-2'
	| 'headline-3'
	| 'headline-4'
	| 'headline-5'
	| 'headline-6'
	| 'sub-headline-1'
	| 'sub-headline-2'
	| 'sub-headline-3'
	| 'sub-headline-4'
	| 'body-1'
	| 'body-2'
	| 'body-3';

type Props = {
	variant: Variants | DeprecatedVariants;
	sm?: Variants;
	md?: Variants;
	lg?: Variants;
	xl?: Variants;
	asEl?: AllowedTags;
	component?: AllowedTags;
	color?: string;
	children?: ReactNode;
} & HTMLAttributes<HTMLElement>;

const deprecatedVariantsMap = {
	h1: 'headline-1',
	h2: 'headline-2',
	h3: 'headline-3',
	h4: 'headline-4',
	h5: 'headline-5',
	h6: 'headline-6',
	subheadline1: 'sub-headline-1',
	subheadline2: 'sub-headline-2',
	subheadline3: 'sub-headline-3',
	subheadline4: 'sub-headline-4',
	body1: 'body-1',
	body2: 'body-2',
	body3: 'body-3',
};

type deprecatedToVariantType = (variant: Variants | DeprecatedVariants) => Variants;
const deprecatedToVariant: deprecatedToVariantType = variant => {
	if (Object.keys(deprecatedVariantsMap).includes(variant)) {
		const mappedVariant = deprecatedVariantsMap[variant as DeprecatedVariants] as Variants;

		deprecationWarning('Typography', `variant '${variant}' is deprecated and will be removed soon, please use variant '${mappedVariant}' instead.`);

		return mappedVariant;
	}

	return variant as Variants;
};

const Typography: FC<Props> = ({ variant = 'body1', sm, md, lg, xl, asEl, component, color, children, className, ...props }) => {
	// deal with deprecated props
	const resolvedVariant = useMemo(() => deprecatedToVariant(variant), [variant]);

	const resolvedColorClass = useMemo(() => {
		if (color == null || className?.includes('text-')) {
			return null;
		}

		const colorClass = `text-${color.split('.').join('-')}`;

		deprecationWarning('Typography', `'color' prop is deprecated and will be removed soon, please use 'className' prop with following value instead 'text-${colorClass}'.`);

		return colorClass;
	}, [color, className]);

	const resolvedAsEl = useMemo(() => {
		if (asEl == null && component != null) {
			deprecationWarning('Typography', `'component' prop is deprecated and will be removed soon, please use 'asEl' prop instead.`);

			return component;
		}

		return asEl;
	}, [asEl, component]);
	// end deal with deprecated props

	const Component = useMemo(() => {
		if (resolvedAsEl !== null && resolvedAsEl !== undefined) {
			return resolvedAsEl;
		}

		if (resolvedVariant && resolvedVariant.startsWith('headline-')) {
			return ('h' + resolvedVariant.split('-')[1]) as AllowedTags;
		}

		return 'p';
	}, [resolvedAsEl, resolvedVariant]);

	const classes = useMemo(
		() =>
			cn(
				{
					'headline-base': resolvedVariant.includes('headline'),
					'sub-headline-base': resolvedVariant.includes('sub-headline'),
					'body-base': resolvedVariant.includes('body'),
				},
				resolvedColorClass,
				resolvedVariant,
				{
					[`sm:${sm}`]: sm !== null && sm !== undefined,
					[`md:${md}`]: md !== null && md !== undefined,
					[`lg:${lg}`]: lg !== null && lg !== undefined,
					[`xl:${xl}`]: xl !== null && xl !== undefined,
				},
				className,
			),
		[resolvedVariant, resolvedColorClass, sm, md, lg, xl, className],
	);

	return (
		<Component className={classes} {...props}>
			{children}
		</Component>
	);
};

export default Typography;
