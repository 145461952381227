'use client';

import Section from '@egalite/ui/IncluiPCD/Section';
import SectionTitle from '@egalite/ui/IncluiPCD/SectionTitle';
import { FC, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { liveStore } from './LiveUpdater';

const Content: FC<{ liveId?: string }> = ({ liveId }) => {
	const snap = useSnapshot(liveStore);

	useEffect(() => {
		liveStore.liveId = liveId;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!snap.liveId || snap.liveId === '' || snap.liveId === 'TBD' || snap.liveId === 'null') return null;

	return (
		<Section id="lives">
			<SectionTitle>Palestras</SectionTitle>

			<iframe
				className="w-full aspect-video"
				src={`https://www.youtube.com/embed/${snap.liveId}?autoplay=1&mute=1`}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowFullScreen
			></iframe>

			{/* <iframe
				className="w-full aspect-video"
				src={`https://www.youtube.com/live_chat?v=${snap.liveId}&embed_domain=${window.location.hostname}`}
				title="YouTube chat"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowFullScreen
			></iframe> */}
		</Section>
	);
};

export default Content;
