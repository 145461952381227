'use client';

import { FC, useMemo } from 'react';
import Opportunity from '../../../types/Opportunity';
import OpportunityCard from '../../OpportunityCard';
import clx from 'classnames';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Icon from '../../../Icon';
import generateOpportunityUrl from './generateOpportunityUrl';

type Props = {
	opportunities: Opportunity[];
};

const OpportunitiesList: FC<Props> = ({ opportunities }) => {
	
	return (
		<div className="w-full px-12 relative sm:-mx-12 sm:w-[calc(100%_+_12rem)]">
			<Swiper
				slidesPerView={1}
				spaceBetween={16}
				breakpointsBase='container'
				breakpoints={{
					720: {
						slidesPerView: 2,
						spaceBetween: 0,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 0,
					},
					1360: {
						slidesPerView: 4,
						spaceBetween: 0,
					},
				}}
				slidesPerGroupAuto
				loop={true}
				className="w-full [&_.swiper-wrapper]:min-h-[310px] [&_.swiper-wrapper]:items-stretch"
				navigation={{
					nextEl: `.button-next-opp`,
					prevEl: `.button-prev-opp`,
				}}
				modules={[Navigation]}
			>
				{opportunities?.map((opportunity, index) => (
					<SwiperSlide className="p-6 h-[unset] flex self-stretch items-stretch justify-center" key={index}>
						<OpportunityCard
							href={generateOpportunityUrl(opportunity)}
							key={opportunity.cod}
							{...opportunity}
							as="li"
							className="shadow-lg"
						/>
					</SwiperSlide>
				))}
			</Swiper>
			<div className="w-12 h-full flex items-center justify-end z-10 absolute top-0 right-0">
				<button type="button" title="Próximo parceiro" className={`button-next-opp button-next disabled:opacity-50 disabled:cursor-default`}>
					<Icon name="right-angle" className="text-3xl" />
				</button>
			</div>
			<div className="w-12 h-full flex items-center justify-start z-10 absolute top-0 left-0">
				<button
					type="button"
					title="Parceiro anterior"
					className={`button-prev-opp button-prev disabled:opacity-50 disabled:cursor-default`}
				>
					<Icon name="left-angle" className="text-3xl" />
				</button>
			</div>
		</div>
	);
};

export default OpportunitiesList;
