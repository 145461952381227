import { ElementType, FC } from 'react';
import Icon from '../Icon';
import Opportunity from '../types/Opportunity';
import cn from '../Utils/cn';
import Link from 'next/link';
import Image from 'next/image';

type Props = {
	as?: ElementType;
	className?: string;
	href?: string;
	companyLogo?: string;
} & Opportunity;

const degreeMap = {
	'Doutorado Completo': 'Doutorado Completo',
	'Doutorado Incompleto': 'Doutorado Incompleto',
	'Mestrado Completo': 'Mestrado Completo',
	'Mestrado Incompleto': 'Mestrado Incompleto',
	'Pós-Graduação Completa': 'Pós-Graduação Completa',
	'Pós-Graduação Incompleta': 'Pós-Graduação Incompleta',
	'Ensino Superior Completo': 'Ens. Superior Completo',
	'Ensino Superior Incompleto': 'Ens. Superior Incompleto',
	'Ensino Técnico Completo': 'Ens. Técnico Completo',
	'Ensino Técnico Incompleto': 'Ens. Técnico Incompleto',
	'Ensino Médio Completo': 'Ens. Médio Completo',
	'Ensino Médio Incompleto': 'Ens. Médio Incompleto',
	'Ensino Fundamental Completo': 'Ens. Fund. Completo',
	'Ensino Fundamental Incompleto': 'Ens. Fund. Incompleto',
};

const formatter = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL',
});

const OpportunityCard: FC<Props> = ({ cod, jobTitle, city, salary = null, edRequirement, sector, as: Tag = 'div', href, className, companyLogo }) => {
	return (
		<Link
			href={href || '/'}
			className={cn(
				'w-[260px] md:w-[280px] xl:w-[330px]',
				'flex-shrink-0 flex flex-col items-start gap-10 px-6 py-6 group',
				'bg-white rounded-xl text-base-grey-5', 
				'border-[1px] border-solid border-transparent hover:border-brand-main transition-all duration-500 ease-in-out',
				className,
			)}
			aria-label="vaga de emprego"
			role="button"
		>
			<div className="flex flex-col lg:flex-row-reverse w-full gap-8">
				{companyLogo && (
					<Image src={companyLogo} alt="logo" width={100} height={100} className="w- h-40 rounded-2xl object-contain border border-solid border-base-grey-2 p-2 bg-white" />
				)}
				<div className='flex flex-col mr-auto'>

					<p className="text-xs text-brand-main font-bold" aria-label="identificador numérico da vaga">
						#{cod}
					</p>

					<p className="text-xl xl:text-4xl font-bold mt-3 font-inter">{jobTitle}</p>

					<ul className="flex flex-wrap gap-x-3 gap-y-4 mt-12 mb-12">
						<li className="md:px-5 md:py-3 flex gap-2 items-center text-base lg:text-[1.8rem] leading-none font-bold text-base-grey-5 md:bg-base-grey-2 rounded-full">
							<Icon name="location" className="text-sm text-brand-main" /> {city ?? 'Remoto'}
						</li>
						{sector && (
							<li className="md:px-5 md:py-3 flex gap-2 items-center text-base lg:text-[1.8rem] leading-none font-bold text-base-grey-5 md:bg-base-grey-2 rounded-full">
								<Icon name="package" className="text-sm text-brand-main" /> {sector}
							</li>
						)}

						<li className="md:px-5 md:py-3 flex gap-2 items-center text-base lg:text-[1.8rem] leading-none font-bold text-base-grey-5 md:bg-base-grey-2 rounded-full">
							<Icon name="schooling" className="text-sm text-brand-main" /> {degreeMap[`${edRequirement}` as keyof typeof degreeMap] ?? edRequirement}
						</li>
						<li className="md:px-5 md:py-3 flex gap-2 items-center text-base lg:text-[1.8rem] leading-none font-bold text-base-grey-5 md:bg-base-grey-2 rounded-full">
							<Icon name="disability" className="text-sm text-brand-main" /> Vaga PCD
						</li>
					</ul>
				</div>
			</div>
			<div className="mt-auto w-full flex justify-between items-end">
				{/* {salary ? (
						<p className="text-lg leading-none">
							<span className="block text-[1.8rem] text-brand-main font-bold mb-2">Salário</span>
							{typeof salary === 'number' ? formatter.format(salary) : salary ?? 'Não informado'}
						</p>
					) : ( */}
				<p></p>
				{/* )} */}

				<span className="inline-block p-3 text-brand-main border border-solid border-current rounded-xl" aria-label="link da vaga">
					<Icon name="right-angle" className="text-lg" />
				</span>
			</div>
		</Link>
	);
};

export default OpportunityCard;
