import { FC, HtmlHTMLAttributes } from 'react';
import Typography from '../Typography';

const SectionTitle: FC<HtmlHTMLAttributes<HTMLHeadingElement>> = ({ children, className, ...props }) => {
	return (
		<Typography variant="headline-5" sm='headline-3' lg="headline-1" asEl="h2" className={className} {...props}>
			{children}
		</Typography>
	);
};

export default SectionTitle;
